<template>
  <div>
    <el-dialog
        title="保证金交易明细" :close-on-click-modal="false"
        :destroy-on-close="true"
        :visible.sync="isOpenModal"
        width="1000px" center @closed="closeModal"
    >
      <div class="main">
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"  :header-cell-style="{background: '#F2F6FC'}"
                  :props="{width: '100%',  size: 'mini'}">
        </ch-table>


      </div>
      <span slot="footer" class="flex_con">
          <ch-page
              type="old" ref="page"
              :getListFunction="getRecordList"
          ></ch-page>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isOpenModal: false,
      tableList: [],
      isTableLoading: false
    }
  },
  computed: {
    tableOption() {
      return [
          {column: 'type', label: '序号', prop: '', type: 'index', width: '50'},
          {column: 'text', label: '交易类型', prop: '_type', showOverflowTooltip: true },
          {column: 'text', label: '交易金额(元)', prop: 'amount', showOverflowTooltip: true },
          {column: 'text', label: '交易内容', prop: 'title', showOverflowTooltip: true},
          {column: 'text', label: '剩余金额(元)', prop: 'balance', showOverflowTooltip: true},
          {column: 'text', label: '交易时间', prop: 'createTime', showOverflowTooltip: true },
      ]
    }
  },
  methods: {
    openModal() {
      this.isOpenModal = true
      this.getRecordList()
    },
    getRecordList() {
      this.isTableLoading = true
      this.$refs?.page?.getServerList('/hm/hmCompanyDeposit/recordList').then((res) => {
        this.tableList = res.rows.map(item=>{
          return {
            ...item,
            _type: item.type === 1 ? '收入' : '支出',
          }
        })
        this.isTableLoading = false
      })
    },
    closeModal() {
      this.isOpenModal = false
    }
  }
}
</script>
<style scoped lang="scss">
.main{
  width: 100%;
}
</style>