<template>
  <div>
    <!--确定充值-->
    <el-dialog
        title="确定充值" :close-on-click-modal="false"
        :destroy-on-close="true"
        :visible.sync="isOpenModal"
        width="600px" center
    >
        <div class="content">
          您即将为「{{rechargeObj.companyName}}」账号充值保证金，请确定是否为本账号。若有账号异常，请联系邻邻發客服。
        </div>
        <span slot="footer" class="flex_con">
          <ch-button type="cancel"  @click="isOpenModal = false">取 消</ch-button>
          <ch-button type="ok" @click="submitModal">确 定</ch-button>
        </span>
    </el-dialog>
    <!--保证金充值-->
    <el-dialog
        title="保证金充值" :close-on-click-modal="false"
        :destroy-on-close="true"
        :visible.sync="isOpenAmountModal"
        width="600px" center
    >
      <div class="main">
        <div class="info">
          <div class="title color-{#3D7FFF} text-18">家政服务最高权限</div>
          <div class="content">目前本账号停服了，保证金为{{rechargeObj.deposit}}元 才可正常使用，本账号还需充值{{rechargeObj.needRechargeMoney}}元</div>
          <div class="amount text-18 weight-600">￥{{rechargeObj.needRechargeMoney}}</div>
        </div>
      </div>
      <span slot="footer" class="flex_con">
        <ch-button type="cancel"  @click="isOpenAmountModal = false">取 消</ch-button>
        <ch-button type="ok" :loading="payLoading" @click="nextModal">去充值</ch-button>
    </span>
    </el-dialog>
    <el-dialog
        title="保证金充值" :close-on-click-modal="false"
        :destroy-on-close="true"
        :visible.sync="isOpenPayModal"
        width="600px" center @closed="closePayModal"
    >
      <div class="main">
        <ch-form :render-option="payOption" :model="payModalForm" :rules="rules"
                 :props="{ labelWidth: '90px' , paddingRight: '0'}"
                 ref="modalForm"
        ></ch-form>
        <div class="ta-center h-227">
          <div class="h-200" v-loading="!codeUrl">
            <vue-qr v-if="codeUrl" :text="codeUrl" :size="200"></vue-qr>
          </div>
          <div>保证金：<span class="text-18 weight-600">￥{{rechargeObj.needRechargeMoney}}</span></div>
        </div>
      </div>
      <span slot="footer" class="flex_con">
        <span class="scanCode"><ch-icon class="mr-8" name="scanCode" />请使用微信"扫一扫"扫描二维码支付</span>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import vueQr from "vue-qr";
import {mapActions} from "vuex";

export default {
  components: {
    vueQr
  },
  data() {
    return {
      isOpenModal: false,
      isOpenAmountModal: false,
      isOpenPayModal: false,
      payLoading: false,
      rechargeObj: {
        deposit: '',
        needRechargeMoney: ''
      },
      payModalForm: {
        payType: '1',
      },
      rules: {
        payType: [
          { required: true, message: '请选择付款类型', trigger: ['change','blur'] },
        ]
      },
      codeUrl: '',
      orderNumber: '',
    };
  },
  computed: {
    payOption() {
      return [
        { type: 'select', label: '付款类型：', prop: 'payType', name: "label", value: "value",
          option:[
            {label: '微信支付', value: '1'},
          ]
        },
      ]
    }
  },
  methods: {
    ...mapActions(['getCompany']),
    openModal(row){
      this.isOpenModal = true
      this.rechargeObj = JSON.parse(JSON.stringify(row))
    },
    submitModal(){
      this.isOpenModal = false
      this.isOpenAmountModal = true
    },
    // 去充值
    nextModal(){
      this.isOpenAmountModal = false
      this.isOpenPayModal = true
      this.getRecharge()
    },
    getRecharge(){
      this.payLoading = true
      this.$curl.get(`/hm/hmCompanyDeposit/recharge/${this.payModalForm.payType}`).then(res=>{
        this.codeUrl = res.data.codeUrl
        this.orderNumber = res.data.orderNumber
        this.isOpenPayModal = true
        this.isOpenAmountModal = false
        // this.getCompany(true)
        // this.$forceUpdate()
      }).finally(()=> this.payLoading = false)
    },
    closePayModal(){
      this.$curl.get(`/hm/hmCompanyDeposit/cancel/${this.orderNumber}`).then(()=>{
        this.isOpenPayModal = false
        this.codeUrl = ''
        this.orderNumber = ''
      })
    },
    // // 上一步
    // prevModal(){
    //   this.isOpenPayModal = false
    //   this.isOpenAmountModal = true
    // },
  }
};
</script>
<style lang="scss" scoped>
.content{
  white-space: pre-line;
  font-size: 16px;
  line-height: 30px;
  color: #333333;
}

.main{
  font-size: 16px;
  color: #333333;
  display: flex;
  flex-direction: column;
  .info{
    width: 70%;
    margin: auto;
    padding: 20px 50px;
    text-align: center;
    border: 1px solid #3D7FFF;
    border-radius: 16px;
  }
  .content{
    line-height: 24px;
    padding: 16px 0;
  }
}
.scanCode{
  line-height: 50px;
  background: rgba(61, 127, 255, 0.08);
  color: #3D7FFF;
  padding: 0 36px 0 16px;
}
</style>